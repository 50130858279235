import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';

import { Section } from './common';
import breakpoints from '../utils/breakpoints';

import gamepadIcon from '../assets/icon-gamepad.svg';
import workIcon from '../assets/icon-work.svg';
import contractIcon from '../assets/icon-contract.svg';

import WorkBenefitItem from './WorkBenefitItem';
import { RoundBox } from './RoundBox';

const DetailContent = ({
  subHeader,
  mainHeader,
  descriptionHeader,
  titleItem1,
  titleItem2,
  titleItem3,
  descriptionItem1,
  descriptionItem2,
  descriptionItem3,
}) => (
  <StyledSection>
    <HeaderWrapper>
      <FirstRoundBox />
      <SecondRoundBox />
      <ContineroSubtitle>
        <FormattedMessage id={subHeader} />
      </ContineroSubtitle>
      <ContineroTitle>
        <FormattedMessage id={mainHeader} />
      </ContineroTitle>
      <TitleDescription>
        <FormattedMessage id={descriptionHeader} />
      </TitleDescription>
    </HeaderWrapper>
    <WorkBenefitWrapper>
      <WorkBenefitItem
        isColumn
        icon={contractIcon}
        titleKey={titleItem1}
        descriptionKey={descriptionItem1}
      />

      <WorkBenefitItem
        isColumn
        icon={workIcon}
        titleKey={titleItem2}
        descriptionKey={descriptionItem2}
      />

      <WorkBenefitItem
        isColumn
        icon={gamepadIcon}
        titleKey={titleItem3}
        descriptionKey={descriptionItem3}
      />
    </WorkBenefitWrapper>
  </StyledSection>
);

export default DetailContent;

const StyledSection = styled(Section)`
  position: relative;
`;

const FirstRoundBox = styled(RoundBox)`
  width: 475px;
  height: 482px;
  top: -300px;
  left: -220px;

  background: linear-gradient(
    134.58deg,
    #fff6df 0.22%,
    rgba(255, 250, 237, 0.4) 99.78%
  );
  border-radius: 90px;
`;

const SecondRoundBox = styled(RoundBox)`
  width: 500px;
  height: 291px;
  top: 100px;
  right: -900px;

  /* Yellow gradient-pattern */

  background: linear-gradient(
    140.82deg,
    #fff6df 0.22%,
    rgba(255, 250, 237, 0.4) 99.78%
  );
  border-radius: 40px;
`;

const ThirdRoundBox = styled(RoundBox)`
  width: 304px;
  height: 365px;

  background: linear-gradient(
    129.79deg,
    #fff6df 0.22%,
    rgba(255, 250, 237, 0.4) 99.78%
  );
  border-radius: 70px;
  left: -400px;
  bottom: 70px;
  width: 500px;
  height: 304px;
`;

const HeaderWrapper = styled.div`
  margin: 0 auto;
  text-align: center;
  position: relative;
`;

const WorkBenefitWrapper = styled.div`
  margin-top: 64px;

  ${breakpoints.tablet} {
    display: flex;

    & > div {
      width: 33.333%;
    }
  }
`;

const ContineroTitle = styled.div`
  font-weight: bold;
  font-size: 64px;
  line-height: 60px;

  position: relative;
  z-index: 3;

  ${breakpoints.mobile} {
    font-size: 100px;
    line-height: 100px;
  }
`;

const ContineroSubtitle = styled.div`
  position: relative;
  z-index: 3;
  font-size: 32px;
  ${breakpoints.mobile} {
    font-size: 48px;
  }
`;

const TitleDescription = styled.div`
  position: relative;
  z-index: 3;
  font-size: 18px;
  line-height: 24px;
  margin: 16px 0;

  ${breakpoints.mobile} {
    margin: 32px 0;

    font-size: 24px;
    line-height: 29px;
  }
`;
