import React from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'gatsby-plugin-intl';
import calendlyLogo from '../assets/calendly_logo.svg';

import { Section, Button, NewTabLink } from './common';

import TitleHeader from './TitleHeader';

const DetailTextFooter = ({ mainHeader, subheader, description }) => (
  <StyledSection>
    <TitleHeader titleKey={mainHeader} descriptionKey={subheader} center />

    <Text>
      <FormattedMessage id={description} />
    </Text>

    <NewTabLink href="https://calendly.com/david-fogl">
      <CalendlyButton backgroundColor="#48A1F8" leftIcon={calendlyLogo}>
        <FormattedMessage id="CalendlyBookButton" />
      </CalendlyButton>
    </NewTabLink>
  </StyledSection>
);

export default DetailTextFooter;

const StyledSection = styled(Section)`
  text-align: center;
`;

const Text = styled.div`
  font-size: 18px;
  line-height: 29px;
  text-align: center;
  color: #8698a4;
  max-width: 650px;
  margin: 0 auto;
`;

const CalendlyButton = styled(Button)`
  margin-top: 48px;
`;
