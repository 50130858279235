import React from 'react';
import { ThemeProvider } from 'styled-components';

import { lightTheme } from '../theme';
import GlobalStyles from '../GlobalStyles';
import Navbar from '../components/Navbar';

import Helmet from '../components/Layout';
import Footer from '../components/Footer';
import RightBoxDescription from '../components/RightBoxDescription';
import LeftBoxDescription from '../components/LeftBoxDescription';

import DetailContent from '../components/DetailContent';
import DetailTextFooter from '../components/DetailTextFooter';

const detailContent = {
  subHeader: 'Detail_Content_subHeader',
  mainHeader: 'Detail_Content_mainHeader',
  descriptionHeader: 'Detail_Content_descriptionHeader',
  titleItem1: 'Detail_Content_titleItem1',
  titleItem2: 'Detail_Content_titleItem2',
  titleItem3: 'Detail_Content_titleItem3',
  descriptionItem1: 'Detail_Content_descriptionItem1',
  descriptionItem2: 'Detail_Content_descriptionItem2',
  descriptionItem3: 'Detail_Content_descriptionItem3',
};

const leftBoxDescriptionProps = {
  mainHeader: 'Detail_LeftBox_mainHeader',
  subHeader: 'Detail_LeftBox_subHeader',
  description1: 'Detail_LeftBox_description1',
  description2: 'Detail_LeftBox_description2',
};

const rightBoxDescriptionProps = {
  mainHeader: 'Detail_RightBox_mainHeader',
  subHeader: 'Detail_RightBox_subHeader',
  description1: 'Detail_RightBox_description1',
  description2: 'Detail_RightBox_description2',
};

const detailTextFooterProps = {
  mainHeader: 'Detail_TextFooter_mainHeader',
  subheader: 'Detail_TextFooter_subheader',
  description: 'Detail_TextFooter_description',
};

const IndexPage = () => (
  <ThemeProvider theme={lightTheme}>
    <Helmet />
    <GlobalStyles />
    <>
      <Navbar />
      <DetailContent {...detailContent} />
      <RightBoxDescription {...rightBoxDescriptionProps} />
      <LeftBoxDescription {...leftBoxDescriptionProps} />
      <DetailTextFooter {...detailTextFooterProps} />
      <Footer />
    </>
  </ThemeProvider>
);

export default IndexPage;
